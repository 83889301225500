<template lang="html">
  <div v-if="!this.loading">
    <div class="top">
      <div class="text_line">
        <div class="lined_text">
          <h1 class="text">Über Olga</h1>
          <div class="long" />
        </div>
      </div>
      <main>
        <p class="top-text">
          Bereits als Teenager habe ich mit großer Begeisterung Comics
          gezeichnet und fand meine Vorlagen bei den wahren Helden wie Garfield,
          Alfred E. Neumann, Calvin & Hobbes, Lucky Luke, Hägar usw. Ihre Art,
          die Welt mit einem Augenzwinkern zu betrachten, wurde zu meinem
          Lebensmotto. Wer kann es mir verübeln, dass ich heute, einige Jahre
          später, mein inneres Kind erweckt und Olga, meinen kleinen Wirbelwind,
          ins Leben gerufen habe. <br /><br />Als ständiger Begleiter hilft sie
          mir nun, meine Arbeit sowie die kleinen und großen Dinge – also das
          ganz normalen Leben – mit anderen Augen zu sehen.
        </p>
        <div class="elements">
          <div
            class="element"
            v-for="element in data.entries"
            :key="element._id"
          >
            <img :src="`http://kreps-grafik.de${element.Image.path}`" alt="" />
            <p>{{ element.Description }}</p>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import CMSService from "../CMS-Service.js";
export default {
  data() {
    return {
      data: new Array(),
      loading: true,
    };
  },
  created() {
    CMSService.getCollectionEntries("Olga").then((data) => {
      this.data = data.data;
    });
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "../main.scss";
main {
  max-width: 1250px;
  margin: auto;
  padding: 0 10%;
}
.top {
  background-color: white;
  padding: 0 33px;
  width: calc(100% - 66px);

  .long {
    border-bottom: 2px solid $k3-red;
    margin-bottom: 18px;
  }

  .text_line {
    max-width: 450px;
    margin-top: 30px;
  }
}

.top-text {
  font-family: "PT Sans";
  font-size: 20px;
  font-weight: regular;
  line-height: 30px;
  margin-top: 84px;
  white-space: pre-wrap;
  text-align: center;
  margin-bottom: 30px;
}

.elements {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 30px;
  .element {
    width: 25vw;
    min-width: 310px;
    max-width: 400px;

    margin: 19px 19px;

    img {
      width: 100%;
    }
    p {
      font-family: "PT Sans";
      font-size: 16px;
      font-weight: regular;
      line-height: 20px;
    }
  }
}

//mobile
@media screen and (max-width: 720px) {
  .text_line {
    max-width: 220px !important;
    margin-top: 0px !important;
  }
  .long {
    margin-bottom: 10px !important;
  }
  .contact {
    display: none;

    #world {
      transform: translate(-50%) !important;
    }
  }
  .description-text {
    margin-top: 25px !important;

    hr {
      display: none !important;
    }
    .text {
      width: 100% !important;
    }
  }
}
</style>
